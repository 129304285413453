/*
* Element: Headers
*/

/*Setting document variables*/
:root {
    --heading-color-primary: var(--color-primary);
    --heading-color-secondary: var(--color-secondary);
    --heading-color-black: var(--color-black);
    --hero-text-color: var(--color-white);
    --footer-text-color: var(--color-white);
    --footer-border-color: var(--color-white);
    --footer-border-opacity: 75%;
}

.heading {
    color: var(--heading-color-primary);
    font-size: 22px;
    font-weight: 600;
    font-family: var(--heading-font-family);
    margin-bottom: 20px;
    margin-top: 0;
    width: 100%;

    &__column {
        color: var(--heading-color-black);
    }

    &__footer {
        color: var(--footer-text-color);
        line-height: 40px;
        margin-bottom: 20px;
        border-bottom: 1px solid
            color-mod(
                var(--footer-border-color) alpha(var(--footer-border-opacity))
            );
    }
}
