:root {
    --nav-trigger-burger-width: 30px;
    --nav-trigger-burger-height: 20px;
    --nav-trigger-burger-stroke-width: 3px;
    --nav-trigger-color: var(--color-black, #fff);
    --nav-trigger-border-color: var(--color-secondary);
    --nav-trigger-border-opacity: 25%;
}

.nav-main-mobile {
    display: flex;
    margin-right: -15px;
    border-left: 1px solid color-mod(var(--nav-trigger-border-color, white) alpha(var(--nav-trigger-border-opacity, 25%)));

    @media (--viewport-sm-min) {
        display: none;
    }
}

.mobile-navigation-open {
    overflow: hidden;
    position: fixed;
    width: 100%;

    .topbar__navigation {
        transform: translateX(0);
        opacity: 1;

        &__item {
            display: block;
        }
    }
}

.mobile-navigation-button {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 100%;
    transform: translateX(0);
    opacity: 1;
    transition: all 0.3s var(--easeInOutQuart);
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    &__text {
        color: var(--color-primary);
        text-align: center;
        font-size: 10px;
    }
}

.hamburger {
    padding: 15px 15px;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    outline: none;
    background-color: transparent;
    width: 80px;
    user-select: none;
}

.hamburger-label {
    font-size: 14px;
    margin-top: 3px;
    transition: all 0.3s var(--easeInOutQuart);
    font-weight: bold;
}

.hamburger-box {
    width: 30px;
    height: 24px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 30px;
    height: 3px;
    background-color: var(--color-primary);
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
    content: '';
    display: block;
}

.hamburger-inner::before {
    top: -9px;
}

.hamburger-inner::after {
    bottom: -9px;
}
