/*
* Element: Label
*/

/*Setting document variables*/
:root {
    --input-text-color: var(--color-text);
    --input-background-color: var(--color-white);
    --input-border-color: var(--color-gray-light);
    --input-font-family: var(--base-font-family);
}

.textarea {
    display: block;
    width: 100%;
    min-height: 100px;
    padding: 10px;
    font-size: 16px;
    line-height: 1;
    font-family: var(--input-font-family);
    color: var(--input-text-color);
    background-color: var(--input-background-color);
    background-image: none; /* Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214*/
    border: 1px solid var(--input-border-color);
    border-radius: var(--border-radius);
    resize: none;
    appearance: none;

    &:focus {
        outline: none;
    }
}
