/*
* Element: Headers
*/

/*Setting document variables*/
:root {
    --heading-page-color-primary: var(--color-text);
    --heading-page-color-secondary: var(--color-secondary);
    --pageheader-header-color: var(--color-white);
}

.heading-page {
    font-size: 24px;
    color: var(--heading-color-primary);
    font-family: var(--heading-font-family);

    &--pageheader {
        margin-top: 0;
        color: var(--pageheader-header-color);
        margin-bottom: 15px;
        text-shadow: 1px 1px 1px #333;
        font-size: 32px;
    }
}
