/*
* Element: Label
*/

/*Setting document variables*/
:root {
    --input-font-family: var(--base-font-family);
}

.label {
    font-family: var(--input-font-family);
    color: currentColor;
    display: inline-block;
    max-width: 100%;
    /* Force IE8 to wrap long content (see https://github.com/twbs/bootstrap/issues/13141)*/
    margin-bottom: 5px;
    font-weight: normal;

    &--block {
        display: block;
    }

    &__sub {
        color: var(--color-gray);
        font-size: 0.8em;
    }
}
