:root {
    --color-checkbox: var(--color-primary);
}

.select {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0 40px 0 15px;
    font-size: 16px;
    line-height: 1;
    font-family: var(--input-font-family);
    color: var(--input-text-color);
    background-color: var(--input-background-color);
    background-image: none; /* Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214*/
    border: 1px solid var(--input-border-color);
    border-radius: var(--border-radius);
    vertical-align: bottom;
    -webkit-appearance: none;
    appearance: none;

    &::-ms-expand {
        display: none;
    }

    &:focus {
        outline: 0;
    }

    &__wrapper {
        position: relative;
    }

    &__icon {
        position: absolute;
        right: 15px;
        top: 14px;
        width: 12px;
        height: 12px;
        z-index: 1;
        pointer-events: none;
        fill: var(--color-text);
    }
}
