/*
 * Element: Buttons
 */

/* Setting document variables*/
:root {
    --button-color-primary: var(--color-primary);
    --button-color-gradient-second-color: #dc4e81;
    --button-color-text: var(--color-white);
    --button-font-family: var(--base-font-family);
    --button-font-size: var(--base-font-size);
}

/* Default button*/

.button {
    cursor: pointer;
    background-image: none;
    /* Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214*/
    background-color: var(--button-color-primary);
    position: relative;
    font-weight: 600;
    font-family: var(--button-font-family);
    font-size: 16px;
    transition: background 0.3s, color 0.3s, border-color 0.3s;
    color: var(--button-color-text);
    border: 1px solid transparent;
    text-decoration: none;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    z-index: 100;

    &:hover,
    &:focus {
        color: var(--button-color-text);
        background: shade(var(--button-color-primary), 10%);
        outline: none;
    }

    &[disabled],
    [disabled] & {
        cursor: not-allowed;
    }

    /* Large button */
    &--large {
        height: 50px;
        line-height: 50px;
        padding: 0 40px;
        font-size: 1.5em;
    }

    /* Ghost button*/
    &--ghost {
        color: var(--button-color-primary);
        border: solid 1px var(--button-color-primary);
        background-color: transparent;

        &:hover,
        &:focus {
            color: var(--button-color-text);
            background-color: var(--button-color-primary);
        }
    }

    &--scale {
        background-color: transparent;
        border: 1px solid var(--button-color-primary);
        will-change: transform;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: var(--button-color-primary);
            opacity: 1;
            transition: 0.4s;
            z-index: -1;
        }

        &:hover {
            &:before {
                transform: scale(0);
                opacity: 0;
            }
        }
    }

    &--leftright {
        background-color: transparent;
        border: 1px solid var(--button-color-primary);
        will-change: transform;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: var(--button-color-primary);
            transition: 0.4s;
            z-index: -1;
        }

        &:hover {
            &:before {
                transform: translateX(100%);
            }
        }
    }

    &--topbottom {
        background-color: transparent;
        border: 1px solid var(--button-color-primary);
        will-change: transform;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: var(--button-color-primary);
            transition: 0.2s;
            z-index: -1;
        }

        &:hover {
            &:before {
                transform: translateY(100%);
            }
        }
    }

    &--gradient {
        background-color: transparent;
        border: 1px solid transparent;
        will-change: transform;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            height: 100%;
            width: 100%;
            background-image: linear-gradient(89deg, var(--button-color-primary) 0, var(--button-color-gradient-second-color) 52%, var(--button-color-primary));
            background-size: 200% auto;
            transition: 0.6s;
            z-index: -1;
        }

        &:hover {
            &:before {
                background-position: right center;
            }
        }
    }
}