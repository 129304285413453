/*
* Element: paragraphs
*/

/*Setting document variables*/
:root {
    --pageheader-color: var(--color-white);
}

.paragraph {
    margin-top: 0;
    margin-bottom: 15px;

    &--pageheader {
        color: var(--pageheader-color);
        margin-top: 0;
        text-shadow: 1px 1px 1px #333;
    }
}
