/*
* Element: Input fields
*/

/*Setting document variables*/

:root {
    --input-text-color: var(--color-text);
    --input-background-color: var(--color-white);
    --input-background-color-disabled: var(--color-gray);
    --input-border-color: var(--color-gray-light);
    --input-font-family: var(--base-font-family);
}

.input {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0 40px 0 15px;
    font-size: 16px;
    line-height: 1;
    font-family: var(--input-font-family);
    color: var(--input-text-color);
    background-color: var(--input-background-color);
    background-image: none;
    /* Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214*/
    border: 1px solid var(--input-border-color);
    border-radius: var(--border-radius);
    vertical-align: bottom;
    appearance: none;

    &:focus {
        outline: 0;
        border-color: var(--input-text-color);
    }

    &__wrapper {
        margin-bottom: 15px;
    }

    &__description {
        font-size: 14px;
        color: var(--color-gray);
        font-style: italic;
        margin-top: 5px;

        &[type='text'],
        &[type='search'],
        &[type='email'],
        &[type='password'],
        &[type='url'],
        &[type='tel'],
        &[type='number'] {
            -webkit-border-radius: 0;
            -webkit-appearance: none;
            appearance: none;
        }
    }
}

/* clears the 'X' from Internet Explorer */
input::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
    display: none;
}
