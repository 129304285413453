.form-field {
    &__label {
        display: block;
        margin-bottom: 5px;
    }

    & + .form-field {
        margin-top: 20px;
    }

    & + .button {
        margin-top: 20px;
        align-self: flex-start;
    }
}
