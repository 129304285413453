/*
 * Modul: Social Media Collection
 */

.social-media-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    margin-top: 20px;

    &__item {
        float: left;
    }

    &__item + &__item {
        margin-left: 10px;
    }
}
