/**
  * @desc Cookie info dialog styling example
  * @author Danni Larsen - DLA
  * @requires a PostCss compiler
*/

:root {
    --cookie-info-bg-color: var(--color-primary, blue);
    --cookie-info-text-color: var(--color-white, white);
    --cookie-info-close-bg-color: var(--color-primary, blue);
    --cookie-info-close-text-color: var(--color-white, white);
    --cookie-info-border-color: var(--color-white, white);
}

/* Styling for the popup itself */
.cookie-info {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: var(--cookie-info-bg-color, gray);
    padding: 15px;
    transition: 0.3s ease-in-out;
    transform: translate3d(0, 100%, 0); /* Hide by default */
    opacity: 0;
    z-index: 999;

    &-open {
        left: 20px;
        bottom: 0;
        border: none;
        background-color: var(--cookie-info-close-bg-color, gray);
        color: var(--cookie-info-close-text-color, white);
        font-size: 12px;
        line-height: 20px;
        border-radius: 2px 2px 0 0;
        transition: all 0.3s;
        position: fixed;
        opacity: 0.6;
        cursor: pointer;
        will-change: transform;
        z-index: 79;

        &:hover {
            opacity: 1;
        }
    }

    &__more {
        margin-left: auto;
        align-items: center;
        display: flex;
        border: 1px solid var(--cookie-info-border-color);

        &:hover {
            color: var(--cookie-info-close-bg-color);
            background-color: var(--cookie-info-text-color);
        }
    }

    &__close {
        margin-left: auto;
        border: 1px solid var(--cookie-info-border-color);

        &:hover {
            color: var(--cookie-info-close-bg-color);
            background-color: var(--cookie-info-text-color);
        }
    }

    &__content {
        display: flex;
        max-width: 1000px;
        margin: 0 auto;
    }

    &__text {
        margin: 6px 0 0;
        color: var(--cookie-info-text-color, white);
    }
}

/*
 *  Open cookie popup styling
 */
body.cookie-info-show {
    /* Show the cookie info */
    .cookie-info {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    /* Hide the button */
    .cookie-info-open {
        opacity: 0;
    }
}
