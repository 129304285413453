/* Settings */
@import '../_base/_normalize.css';
@import '../_base/_variables.css';
@import '../_base/_colors.css';
@import '../_base/_base.css';
@import '../_base/_fonts.css';
@import '../_base/_grid.css';
/* Atoms */
@import '../01-atoms/buttons/button/button.css';
@import '../01-atoms/headings/heading/heading.css';
@import '../01-atoms/headings/heading-page/heading-page.css';
@import '../01-atoms/forms/input/input.css';
@import '../01-atoms/forms/checkbox/checkbox.css';
@import '../01-atoms/forms/datepicker/datepicker.css';
@import '../01-atoms/forms/radio/radio.css';
@import '../01-atoms/forms/label/label.css';
@import '../01-atoms/forms/select/select.css';
@import '../01-atoms/forms/textarea/textarea.css';
@import '../01-atoms/forms/validation/validation.css';
@import '../01-atoms/image/image.css';
@import '../01-atoms/links/link/link.css';
@import '../01-atoms/links/link-button/link-button.css';
@import '../01-atoms/links/link-social/link-social.css';
@import '../01-atoms/loaders/loader-bar/loader-bar.css';
@import '../01-atoms/loaders/loader-spinner/loader-spinner.css';
@import '../01-atoms/logo/logo.css';
@import '../01-atoms/paragraph/paragraph.css';
@import '../01-atoms/svg-text/svg-text.css';
@import '../01-atoms/scroll-progress-bar/scroll-progress-bar.css';

/* Molecules */
@import '../02-molecules/box/box/box.css';
@import '../02-molecules/box/box-image-duotone/box-image-duotone.css';
@import '../02-molecules/box/box-image/box-image.css';
@import '../02-molecules/box/icon-box/icon-box.css';
@import '../02-molecules/box/text-box/text-box.css';
@import '../02-molecules/form-field/form-field.css';
@import '../02-molecules/link-list/link-list.css';
@import '../02-molecules/navigation/nav-main-list/nav-main-list.css';
@import '../02-molecules/navigation/nav-trigger/nav-trigger.css';
@import '../02-molecules/navigation/nav-trigger/nav-trigger--slider.css';
@import '../02-molecules/navigation/nav-trigger/nav-trigger--spin.css';
@import '../02-molecules/navigation/nav-trigger/nav-trigger--squeeze.css';
@import '../02-molecules/navigation/nav-main-list-priority/nav-main-list-priority.css';
@import '../02-molecules/social-media-collection/social-media-collection.css';
@import '../02-molecules/cookie-info/cookie-info.css';
@import '../02-molecules/list-page-item/list-page-item.css';
@import '../02-molecules/list-page-link/list-page-link.css';
@import '../02-molecules/breadcrumb/breadcrumb.css';
@import '../02-molecules/search-filter/search-filter.css';
@import '../02-molecules/pageheader-content/pageheader-content.css';
@import '../02-molecules/overlap-item/overlap-item.css';

/* Organisms */
@import '../03-organisms/footer/footer.css';
@import '../03-organisms/section/image-text-section/image-text-section.css';
@import '../03-organisms/section/text-image-section/text-image-section.css';
@import '../03-organisms/list-page/list-page.css';
@import '../03-organisms/topbar/topbar/topbar.css';
@import '../03-organisms/topbar/topbar-related/topbar-related.css';
@import '../03-organisms/pageheaders/pageheader-image/pageheader-image.css';
@import '../03-organisms/pageheaders/pageheader-image-alt/pageheader-image-alt.css';
@import '../03-organisms/pageheaders/pageheader-slider/pageheader-slider.css';
@import '../03-organisms/pageheaders/pageheader-video/pageheader-video.css';
@import '../03-organisms/pageheaders/pageheader-dots/pageheader-dots.css';

/* not included in this version @import '../03-organisms/pageheaders/pageheader-video-fullscreen/pageheader-video-fullscreen.css'; */

/* Pages */
@import '../04-pages/overlapping-sections/overlapping-sections.css';

/* NPM modules css */
@import '../../node_modules/aos/dist/aos.css';

.selector,
.selector-secondary,
.selector[type='text'] {
    padding: 15px;
    margin: 0px 0px 15px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 1px 2px #ccc, inset 0 1px 0 #ffffff;
}