/*
 * Module: Nav Main List
 */
:root {
    --nav-main-link-color: var(--color-black);
    --nav-main-link-color-active: var(--color-primary);
}

.nav-main {
    @media (--viewport-sm-min) {
        display: block;
        font-size: 18px;
        width: calc(100% - 400px);
    }
}

.nav-main-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav-main-list__item {
    display: block;
    color: var(--nav-main-link-color);
    @media (--viewport-sm-min) {
        margin-right: 50px;
        display: inline-block;
        margin-bottom: 0;
    }
    a {
        color: currentColor;
        text-decoration: none;
    }

    &:hover {
        color: var(--nav-main-link-color);

        a {
            color: currentColor;
            text-decoration: underline;
        }
    }

    &:last-child {
        margin-right: 0;
    }
}

.nav-main-list__item--active {
    color: var(--nav-main-link-color-active);
    text-decoration: underline;
}
