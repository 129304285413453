.overlap-item {
    position: relative;

    &--above {
        z-index: 1;
    }

    &--below {
        z-index: 0;
    }

    @media (--viewport-xs-min) {
        &-xs {
            &--top {
                top: -12vw;
            }

            &--right {
                right: -12vw;
            }

            &--bottom {
                bottom: -12vw;
            }

            &--left {
                left: -12vw;
            }
        }
    }

    @media (--viewport-sm-min) {
        &-sm {
            &--top {
                top: -10vw;
            }

            &--right {
                right: -10vw;
            }

            &--bottom {
                bottom: -10vw;
            }

            &--left {
                left: -10vw;
            }
        }
    }

    @media (--viewport-md-min) {
        &-md {
            &--top {
                top: -8vw;
            }

            &--right {
                right: -8vw;
            }

            &--bottom {
                bottom: -8vw;
            }

            &--left {
                left: -8vw;
            }
        }
    }

    @media (--viewport-lg-min) {
        &-lg {
            &--top {
                top: -6vw;
            }

            &--right {
                right: -6vw;
            }

            &--bottom {
                bottom: -6vw;
            }

            &--left {
                left: -6vw;
            }
        }
    }

    @media (--viewport-xl-min) {
        &-xl {
            &--top {
                top: -4vw;
            }

            &--right {
                right: -4vw;
            }

            &--bottom {
                bottom: -4vw;
            }

            &--left {
                left: -4vw;
            }
        }
    }
}
