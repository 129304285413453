/**
 * Component: Pageheader-image
 */
:root {
    --hero-text-color: var(--color-white);
    --hero-background-color: var(--color-black);
    --hero-content-opacity: 20%;
}

.pageheader-image {
    color: var(--hero-text-color);
    padding-bottom: 60px;
    padding-top: 60px;
    position: relative;
    background-size: cover;

    @media (--viewport-md-min) {
        height: 500px;
    }

    @media (--viewport-lg-min) {
        height: 700px;
    }

    &:before {
        background-color: color-mod(
            var(--hero-background-color) alpha(var(--hero-content-opacity))
        );
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
    }

    &__inner {
        position: relative;

        @media (--viewport-md-min) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
