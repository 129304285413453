/*
 * Module: Topbar
 */

:root {
    --topbar-background-color: var(--color-white);
    --topbar-border-color: var(--color-secondary);
    --topbar-border-opacity: 25%;
    --topbar-site-width: var(--site-width);
}

.topbar {
    background-color: var(--topbar-background-color);

    &__inner {
        display: flex;

        @media (--viewport-sm-min) {
            align-items: center;
        }
    }

    &__logo {
        height: 85px;
        margin-right: auto;
        padding-top: 20px;
        padding-bottom: 20px;

        @media (--viewport-sm-min) {
            flex-basis: 30%;
            height: 100%;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-left: auto;
        }
    }

    &__navigation {
        padding-bottom: 20px;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;

        @media (--viewport-sm-min) {
            border-right: 1px solid color-mod(var(--topbar-border-color, white) alpha(var(--topbar-border-opacity, 25%)));

            flex-basis: 70%;
            max-width: 70%;
            padding-left: 0;
            padding-right: 0;
            padding-top: 20px;
        }
    }

    &__navigation-flex {
        justify-content: flex-end;
        display: flex;
        flex-direction: column-reverse;

        @media (--viewport-sm-min) {
            display: block;
        }
    }

    &__navigation-primary {
        align-items: center;
        display: flex;
        flex-direction: column;

        @media (--viewport-sm-min) {
            padding-right: 20px;
            flex-direction: row;
            padding-top: 20px;
        }
    }

    &__navigation-secondary {
        border-top: 1px solid color-mod(var(--topbar-border-color) alpha(var(--topbar-border-opacity)));
        color: currentColor;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: right;

        @media (--viewport-sm-min) {
            border-top: none;
            border-bottom: 1px solid color-mod(var(--topbar-border-color) alpha(var(--topbar-border-opacity)));
            padding-right: 20px;
        }
    }

    &__navigation-search {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;

        @media (--viewport-sm-min) {
            margin-left: auto;
            width: 300px;
        }
    }
}

@media (--viewport-ms-max) {
    .topbar__navigation {
        background-color: var(--color-white);
        position: fixed;
        width: 100%;
        right: 0;
        left: 0;
        top: 85px;
        bottom: 0;
        z-index: 80;
        height: 100vh;
        transform: translateX(100%);
        opacity: 0;
        transition: all 0.3s var(--easeInOutQuart);
        overflow-y: auto;
        padding-bottom: 30px;
    }
}
